import React, { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

export type FHTitleProps = {
  text?: string;
  variant: "h1" | "h2" | "h3" | "h4" | "body1" | "subtitle1";
  colour?:
    | "green"
    | "springGreen"
    | "orange"
    | "duckEgg"
    | "midGrey"
    | "lightGrey"
    | "white";
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.primary.dark,
  },
  springGreen: {
    color: theme.palette.primary.main,
  },
  orange: {
    color: theme.palette.secondary.main,
  },
  duckEgg: {
    color: "#8AD4D6",
  },
  midGrey: {
    color: theme.palette.action.active,
  },
  lightGrey: {
    color: theme.palette.divider,
  },
  white: {
    color: theme.palette.background.paper,
  },
}));

export const FHTitle: React.FC<FHTitleProps> = ({
  text,
  variant,
  colour,
  children,
  className,
}: PropsWithChildren<FHTitleProps>) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={classnames(className, {
        ...(colour && classes[colour] && { [classes[colour]]: !!colour }),
      })}
      data-testid="title"
    >
      {text || children}
    </Typography>
  );
};
